.main_main_mex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D22A2B;
}

.mixed_image {
    margin:  1rem;
}

.mixed_image img {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width:700px) {
    .mixed_image {
        margin: 1rem;
    }
}